import { SVGProps } from "react"
const GlobalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9987 20.1666C16.0613 20.1666 20.1654 16.0625 20.1654 10.9999C20.1654 5.93731 16.0613 1.83325 10.9987 1.83325C5.93609 1.83325 1.83203 5.93731 1.83203 10.9999C1.83203 16.0625 5.93609 20.1666 10.9987 20.1666Z"
      stroke="#292D32"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33411 2.75H8.25078C6.46328 8.10333 6.46328 13.8967 8.25078 19.25H7.33411"
      stroke="#292D32"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 2.75C15.5375 8.10333 15.5375 13.8967 13.75 19.25"
      stroke="#292D32"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 14.6667V13.75C8.10333 15.5375 13.8967 15.5375 19.25 13.75V14.6667"
      stroke="#292D32"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 8.25005C8.10333 6.46255 13.8967 6.46255 19.25 8.25005"
      stroke="#292D32"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default GlobalIcon

"use client"

const PolygonIcon = () => {
  return (
    <svg
      width="101"
      height="141"
      viewBox="0 0 101 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_23018_101888)">
        <path
          d="M97.8335 106.924C99.4381 107.485 99.4496 109.75 97.8508 110.327L14.9299 140.256C7.66527 142.878 0.000488624 137.496 0.000488948 129.772L0.000491238 75.2778C0.00049129 74.0344 1.22796 73.1631 2.40177 73.5733L97.8335 106.924Z"
          fill="url(#paint0_linear_23018_101888)"
          fill-opacity="0.24"
        />
        <path
          d="M50.5435 4.55661C49.285 2.21364 46.8406 0.751953 44.181 0.751953H11.146C4.99052 0.751953 0.000488281 5.74198 0.000488281 11.8975V61.8771C0.000488281 63.3979 0.953371 64.7558 2.3836 65.273L98.1945 99.9213C99.7523 100.485 101.183 98.8283 100.399 97.3689L50.5435 4.55661Z"
          fill="url(#paint1_linear_23018_101888)"
          fill-opacity="0.24"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_23018_101888"
          x1="51.3164"
          y1="72.7341"
          x2="51.3164"
          y2="145.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23018_101888"
          x1="51.3164"
          y1="0.751953"
          x2="51.3164"
          y2="101.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_23018_101888">
          <rect y="0.751953" width="100.774" height="140.248" rx="11.1455" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PolygonIcon

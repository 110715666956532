"use client"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import { Fragment } from "react"
import { useInView } from "react-intersection-observer"
import { PolygonIcon, PolygonIconEng } from "../icons"
import NewText from "../NewText/NewText"
import TransitionNumbersCounter from "../transitions/TransitionNumbersCounter"
import { useInViewConfig } from "../transitions/utils"

const StyledContainer = styled.div<{ itemsNumber: number }>`
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: ${(props) =>
    props.itemsNumber && props.itemsNumber > 6
      ? "182px 1px 182px 1px 182px"
      : "182px 1px 182px"}; */
  gap: 24px;
  row-gap: 24px;
  margin-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const DescriptionWrapper = styled.div``

const StyledGridItem = styled.div<{
  $backgroundColor: string
  $color: string
  $hasMargin: boolean
  $locale: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
  background: ${(p) =>
    p.$locale === "he"
      ? "linear-gradient(90deg, #1d4973 100%, #317bbf 60.44%, #3684cc 100%)"
      : "linear-gradient(90deg, #3684cc 0%, #317bbf 60.44%, #1d4973 100%)"};
  min-height: 140px;
  height: auto;
  box-shadow: 0px 4px 12px 0px #00000014;
  border-radius: 8px;
  padding: 16px 24px 16px 24px;
  border: 2px solid #2768a3;
  justify-content: center;
`

const StyledRichTextWrapper = styled.div`
  p {
    margin: 0px;
    ${(props) => props.theme.breakpoints.up("md")} {
    }

    &:first-child {
    }
  }
`

const StyledIconWrapper = styled.div`
  height: 100%;
  position: absolute;
  right: -10px;
`

type Props = {
  data: {
    title?: string
    number?: number | string
    textNextToNumber?: string
    text?: string
  }[]
  accentColor: string
  background?: string
  textColor?: string
  backgroundColor?: string
}

const NumbersGrid: React.FC<Props> = ({
  data,
  accentColor,
  background = "white",
  textColor = "black",
}) => {
  const numbersInViewConfig = {
    ...useInViewConfig,
    // rootMargin: undefined,
    threshold: 0,
    delay: 500,
    initialInView: false,
    trackVisibility: true,
  }
  const { ref, inView } = useInView(numbersInViewConfig)
  const itemsNumber = data.length
  const locale = useLocale()
  return (
    <>
      <StyledContainer ref={ref} itemsNumber={itemsNumber}>
        {data.map((item, index) => (
          <Fragment key={index.toString() + "D"}>
            <StyledGridItem
              $hasMargin={index % 3 === 0}
              $backgroundColor={accentColor}
              $color={background}
              $locale={locale}
            >
              <NewText
                desktopFontSize={56}
                desktopFontWeight="600"
                desktopLineHeight="100%"
                mobileFontSize={56}
                mobileFontWeight="600"
                component="div"
                color={textColor}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <NewText
                  desktopFontSize={56}
                  desktopFontWeight="600"
                  desktopLineHeight="100%"
                  mobileFontSize={56}
                  component="p"
                  color={textColor}
                  style={{ wordBreak: "break-word" }}
                >
                  <TransitionNumbersCounter inView={inView} number={item.number || 0} />
                </NewText>
                <NewText
                  desktopFontSize={16}
                  desktopFontWeight="400"
                  desktopLineHeight="23px"
                  component="p"
                  color={textColor}
                  marginLeft={"8px"}
                >
                  {item.textNextToNumber}
                </NewText>
              </NewText>
              <StyledRichTextWrapper>
                <NewText
                  desktopFontSize={16}
                  desktopFontWeight="400"
                  desktopLineHeight="23px"
                  component="div"
                  color={textColor}
                  dangerouslySetInnerHTML={{ __html: item.text || "" }}
                  margin={"0px 2px 0px 0"}
                />
              </StyledRichTextWrapper>
              {item.title && (
                <DescriptionWrapper>
                  <NewText
                    desktopFontSize={14}
                    desktopFontWeight="400"
                    desktopLineHeight="100%"
                    mobileFontSize={12}
                    mobileFontWeight="400"
                    mobileLineHeight="100%"
                    component="p"
                    color={textColor}
                    marginLeft={"4px"}
                  >
                    {item.title}
                  </NewText>
                </DescriptionWrapper>
              )}
              <StyledIconWrapper>
                {locale === "he" ? <PolygonIcon /> : <PolygonIconEng />}
              </StyledIconWrapper>
            </StyledGridItem>
          </Fragment>
        ))}
      </StyledContainer>
    </>
  )
}
export default NumbersGrid

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { footerMenuClick, getNonUmbracoUrl } from "utils"
import NewText from "../../NewText/NewText"
import { Mobile } from "../../common/bit/MediaQueries"

const StyledWithBorders = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 24px 0;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.footerTextColor};
  a {
    text-decoration: none;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 300px;
  }
`

const StyledLine = styled.div`
  height: 20px;
  width: 1px;
  background: ${(props) => props.theme.colors.footerTextColor};
`

type Props = { mobileUrls: any; englishDesktop: any }

function FooterLinksMobile({ mobileUrls, englishDesktop }: Props) {
  const locale = useLocale()
  const siteMapUrl = (url: any) => {
    if (locale === "he") {
      return getNonUmbracoUrl(url)
    } else {
      return `en/${getNonUmbracoUrl(url)}`
    }
  }

  const theme = useTheme()
  return (
    <Mobile>
      <StyledWithBorders>
        {mobileUrls.map((item: any, index: number) => {
          return (
            <Link
              href={
                item.name === "sitemap" ? siteMapUrl(item?.url) : getNonUmbracoUrl(item?.url) || "#"
              }
              key={index.toString()}
              onClick={() => footerMenuClick(item?.name, "")}
            >
              <NewText
                desktopFontSize={16}
                component="p"
                desktopLineHeight="16px"
                color={theme.colors.footerTextColor}
                mobileFontSize={14}
                mobileLineHeight={"20px"}
              >
                {item?.name}
              </NewText>
              <StyledLine />
            </Link>
          )
        })}
        <Link
          href={englishDesktop?.[0]?.url || "/"}
          onClick={() => footerMenuClick(englishDesktop?.[0]?.name, "")}
        >
          <NewText
            desktopFontSize={16}
            color={theme.colors.footerTextColor}
            component="p"
            mobileFontSize={14}
            mobileLineHeight={"20px"}
          >
            {englishDesktop?.[0]?.name}
          </NewText>
        </Link>
      </StyledWithBorders>
    </Mobile>
  )
}

export default FooterLinksMobile

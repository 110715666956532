"use client"
import { useTheme } from "@emotion/react"

const CheckIcon = ({ noCircle }: { noCircle?: boolean }) => {
  const theme = useTheme()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
      {noCircle ? null : <circle cx="11.25" cy="11" r="11" fill="white" />}

      <path
        d="M9.2517 15.4713C9.49406 15.7149 9.90606 15.7149 10.1484 15.4713L17.2736 8.30881C17.5159 8.06519 17.5159 7.65103 17.2736 7.40741L16.4011 6.53037C16.1588 6.28675 15.771 6.28675 15.5286 6.53037L9.71217 12.3773L6.97358 9.64873C6.73123 9.40511 6.34346 9.40511 6.10111 9.64873L5.22864 10.5258C4.98629 10.7694 4.98629 11.1836 5.22864 11.4272L9.2517 15.4713Z"
        fill={theme.colors.propBlue}
      />
    </svg>
  )
}
export default CheckIcon

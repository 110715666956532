import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import NewText from "../../NewText/NewText"
import { Desktop } from "../../common/bit/MediaQueries"
import { MintIcon } from "../../icons"

const StyledGap = styled.div`
  display: flex;
  gap: 40px;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 24px;
    width: auto;
  }
`

type Props = { englishDesktop: any; text: string }

function Copyright({ text }: Props) {
  const theme = useTheme()
  return (
    <StyledGap>
      <NewText
        desktopFontSize={14}
        color={theme.colors.footerTextColor}
        component="p"
        mobileFontSize={12}
      >
        {text}
      </NewText>
      <Desktop>
        <MintIcon fill={theme.colors.footerTextColor} />
      </Desktop>
    </StyledGap>
  )
}

export default Copyright

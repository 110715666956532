"use client"

const RedNoteIcon = () => {
  return (
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.4993 43.0834C34.2702 43.0834 43.0827 34.2709 43.0827 23.5001C43.0827 12.7292 34.2702 3.91675 23.4993 3.91675C12.7285 3.91675 3.91602 12.7292 3.91602 23.5001C3.91602 34.2709 12.7285 43.0834 23.4993 43.0834Z"
        stroke="#C92418"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 15.6667V25.4584"
        stroke="#C92418"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4883 31.3333H23.5059"
        stroke="#C92418"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default RedNoteIcon

"use client"

import { useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import { ChevronLeft } from "@mui/icons-material"
import { Button } from "@mui/material"
import { GetSearchQuery } from "apollo"
import GET_SEARCH from "apollo/queries/GET_SEARCH"
import { useLocale } from "next-intl"
import Link from "next/link"
import { Culture, extractSearchResults, getNonUmbracoUrl, searchItemClick } from "utils"
import ImageContainer from "../ImageContainer"
import NewText from "../NewText/NewText"
import { useIsDesktop } from "../common/bit/MediaQueries"
import { sharedColors } from "../theme/colors"

const EmptyResultsBox = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 300px;
  justify-content: center;
  align-items: center;
`
const EmptyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSearchResultsContainer = styled.div<{
  $width?: string
  $top?: string
  $height?: string
}>`
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  position: absolute;
  top: 168px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  width: calc(100vw - 32px);
  height: auto;
  max-height: ${(p) => (p.$height ? p.$height : "472px")};
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    top: ${(p) => (p.$top ? p.$top : "202px")};
    width: ${(p) => (p.$width ? p.$width : "1040px")};
  }
`

const StyledSearchResults = styled.div<{
  $width?: string
  $top?: string
  $height?: string
}>`
  width: calc(100vw - 32px);
  height: auto;
  max-height: ${(p) => (p.$height ? p.$height : "472px")};
  overflow-y: scroll;
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid #6f6f6f;
  top: ${(p) => (p.$top ? p.$top : "168px")};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  z-index: 9;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    top: ${(p) => (p.$top ? p.$top : "202px")};
    width: ${(p) => (p.$width ? p.$width : "1040px")};
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.propBlue};
    border: 8px solid #fff;
    border-radius: 200px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.propBlue};
  }
`
const StyledSearchedItem = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--Grey, #6f6f6f);
  border-right: 2px solid #f7f7f7;
  padding: 16px;
  display: flex;
  /* max-height: 136px; */
  align-items: center;
  gap: 16px;
`

const StyledInfo = styled.div<{ column: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: space-between;
  gap: 8px;
  text-align: left;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-grow: 1;
    gap: 16px;
  }
`
const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledImage = styled.div`
  border-radius: 16px;
  overflow: hidden;
`
const StyledButton = styled(Button)<{ $locale: string }>`
  text-decoration: none;
  svg {
    transform: ${(p) => (p.$locale === "he" ? "none" : "rotate(180deg)")};
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    height: min-content;
  }
`
const BottomText = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down("md")} {
    align-items: flex-start;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`

function EmptyBox({
  onClick,
  term,
  locale,
  width,
  top,
  height,
}: {
  onClick: () => void
  term: string
  locale: string
  width?: string
  top?: string
  height?: string
}) {
  return (
    <StyledSearchResultsContainer $width={width} $top={top} $height={height}>
      <StyledSearchResults $width={width} $top={top} $height={height}>
        <EmptyResultsBox>
          <EmptyText>
            <NewText
              component={"h3"}
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              color={sharedColors.black}
              textAlign={"center"}
            >
              {locale === "he" ? "לא מצאנו את מה שחיפשת" : "No results found"}
            </NewText>
            <NewText
              component={"h3"}
              desktopFontSize={18}
              desktopLineHeight={"26px"}
              color={sharedColors.black}
              textAlign={"center"}
            >
              {locale === "he" ? "לא מצאנו תוצאות עבור:" : "We did not find any results for:"}
              {` ${term}`}
            </NewText>
          </EmptyText>
          <Link href={locale === "en" ? "/en" : "/"} onClick={onClick}>
            <Button variant="outlined">
              {locale === "he" ? "חזרה לעמוד הבית" : "Back to home page"}
            </Button>
          </Link>
        </EmptyResultsBox>
      </StyledSearchResults>
    </StyledSearchResultsContainer>
  )
}

type Props = {
  handleClose: () => void
  projectIDs: number[]
  pageIDs: number[]
  culture: Culture
  word: string
  isLoadingQuery: boolean
  idNumber: string[]
  searchRouteString: string
  width?: string
  top?: string
  height?: string
}
function Results({
  handleClose,
  culture,
  word,
  pageIDs,
  projectIDs,
  isLoadingQuery,
  idNumber,
  searchRouteString,
  width,
  top,
  height,
}: Props) {
  const desktop = useIsDesktop()
  const locale = useLocale()
  const { data, loading } = useQuery<GetSearchQuery>(GET_SEARCH({ pageIDs, projectIDs }), {
    variables: {
      route: searchRouteString,
      culture,
    },
    fetchPolicy: "network-only",
  })
  if (loading || isLoadingQuery) {
    return null
  }
  if ((!projectIDs || projectIDs.length === 0) && (!pageIDs || pageIDs.length === 0)) {
    return <EmptyBox onClick={handleClose} term={word} locale={locale} width={width} top={top} />
  }
  const projectsData = extractSearchResults(idNumber, desktop, locale, data)

  if (!isLoadingQuery && !loading) {
    return (
      <>
        {projectsData?.length && projectsData?.length > 0 ? (
          <StyledSearchResultsContainer $width={width} $top={top} $height={height}>
            <StyledSearchResults $width={width} $top={top} $height={height}>
              {projectsData.map((item, index: number) => {
                return (
                  <StyledSearchedItem key={index.toString()}>
                    <StyledImage>
                      {item.image ? (
                        <Link
                          onClick={() => {
                            handleClose()
                            searchItemClick(item?.name, word)
                          }}
                          href={getNonUmbracoUrl(item?.url)}
                        >
                          <ImageContainer
                            desktopUrl={item.image}
                            height={106}
                            width={106}
                            mobileHeight={106}
                            mobileWidth={106}
                          />
                        </Link>
                      ) : null}
                    </StyledImage>
                    <StyledInfo column={item.type === "פרויקטים" || item.type === "Project"}>
                      <StyledTitle>
                        <Link
                          onClick={() => {
                            handleClose()
                            searchItemClick(item?.name, word)
                          }}
                          style={{ textDecoration: "none" }}
                          href={getNonUmbracoUrl(item?.url)}
                        >
                          <NewText
                            component={"span"}
                            desktopFontSize={18}
                            desktopLineHeight={"22px"}
                            desktopFontWeight={600}
                            color={sharedColors.black}
                          >
                            {item.title}
                          </NewText>
                        </Link>
                        <NewText
                          component={"span"}
                          desktopFontSize={16}
                          desktopLineHeight={"20px"}
                          color={sharedColors.black}
                        >
                          {item.type}
                        </NewText>
                      </StyledTitle>
                      <BottomText>
                        {item.text ? (
                          <Link
                            onClick={() => {
                              handleClose()
                              searchItemClick(item?.name, word)
                            }}
                            style={{ textDecoration: "none" }}
                            href={getNonUmbracoUrl(item?.url)}
                          >
                            <NewText
                              component={"p"}
                              desktopFontSize={18}
                              color={sharedColors.black}
                            >
                              {item.text}
                            </NewText>
                          </Link>
                        ) : (
                          <div></div>
                        )}

                        <Link
                          onClick={() => {
                            handleClose()
                            searchItemClick(item.name, word)
                          }}
                          href={getNonUmbracoUrl(item?.url)}
                        >
                          <StyledButton
                            variant="text"
                            size="small"
                            endIcon={<ChevronLeft />}
                            $locale={locale}
                          >
                            {locale === "he" ? "מידע נוסף" : "More info"}
                          </StyledButton>
                        </Link>
                      </BottomText>
                    </StyledInfo>
                  </StyledSearchedItem>
                )
              })}
            </StyledSearchResults>
          </StyledSearchResultsContainer>
        ) : (
          <EmptyBox
            onClick={handleClose}
            term={word}
            locale={locale}
            width={width}
            top={top}
            height={height}
          />
        )}
      </>
    )
  }
}

export default Results

import { SVGProps } from "react"
const SendError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.7801 34.5375C40.8602 37.7776 37.7776 40.8602 34.5375 39.7801L2.83431 29.2124C-0.697076 28.0353 -1.00696 23.1609 2.34694 21.546L13.7983 16.0324C14.7747 15.5623 15.5624 14.7745 16.0325 13.7981L21.5461 2.34696C23.1609 -1.00695 28.0353 -0.697097 29.2124 2.83431L39.7801 34.5375ZM35.5457 36.7555C36.2934 37.0047 37.0048 36.2934 36.7555 35.5457L26.1878 3.84252C25.9162 3.02758 24.7913 2.95607 24.4187 3.73005L18.9051 15.1812C18.7484 15.5069 18.5704 15.8199 18.373 16.1186L27.1925 24.9382C27.8151 25.5607 27.8151 26.57 27.1925 27.1926C26.57 27.8151 25.5607 27.8151 24.9381 27.1926L16.1185 18.373C15.8199 18.5703 15.5069 18.7483 15.1814 18.905L3.73005 24.4186C2.95607 24.7913 3.02758 25.9161 3.84252 26.1878L35.5457 36.7555Z"
      fill="#C92418"
    />
  </svg>
)
export default SendError

export const sharedColors = {
  black: "#000000",
  white: "#FFFFFF",
  red: "#FF0000",
  darkRed: "#D10040",
  green: "#00FF00",
  darkBlue: "#153E69",
  primaryDarkBlue: "#1A2B68",
  bluishGray: "#BAD2D4",
  propBlue: "#2768A3",
  yellow: "#FCBB00",
  gray: "#6F6F6F",
  lightGray: "#6F6F6F",
  disableGray: "#696F7A",
  mint: "#040B14",
  blue: "#0050C9",
  lighterBlack: "#666666",
  darkGray: "#5C6474",
  lightGreen: "#66CC00",
  sliderBlue: "#2642A6",
  darkestBlue: "#1B1F2F",
  blueMain: "#0916BC",
  lightGrey: "#F7F7F7",
  corpBlue: "#2768A3",
  darkGreen: "rgba(91, 117, 83, 1)",
  bonitaFooter: "#4A4A4A",
  boniteDarkBlue: "#0D0630",
  heroBackgroundEn: `linear-gradient(180deg, #1d4973 0%, #3684cc 116.35%)`,
  heroBackgroundHe: `linear-gradient(180deg, #1d4973 100%, #3684cc 116.35%)`,
  heroTextColor: "#FFF",
  heroSvgIconColor: "#FFF",
  filterModalHeaderColor: "#2768A3",
  filterModalHeaderTextColor: "#FFF",
  footerColor: "#2768A3",
  socialColor: "#FFF",
  footerTextColor: "#FFF",
  faqRootBackground: "linear-gradient(90deg, #1d4973 0%, #317bbf 60.44%, #3684cc 100%)",
  faqRootInsideBackground: "white",
  faqBorderColor: "#2768A3",
  faqTextColor: "#FFF",
  faqIconColor: "#2768A3",
  faqIconColorAlternative: "#FFF",
  faqBoxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
}

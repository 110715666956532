"use client"
import { useTheme } from "@emotion/react"
import { SVGProps } from "react"
const FormSubmitSuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  const { fill } = props
  const theme = useTheme()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5375 0.219858C37.7776 -0.860189 40.8602 2.22238 39.7801 5.46252L29.2124 37.1657C28.0353 40.6971 23.1609 41.007 21.546 37.6531L16.0324 26.2017C15.5623 25.2253 14.7745 24.4376 13.7982 23.9675L2.34696 18.4539C-1.00695 16.8391 -0.697096 11.9647 2.83431 10.7876L34.5375 0.219858ZM36.7555 4.45432C37.0047 3.70659 36.2934 2.99523 35.5457 3.24447L3.84252 13.8122C3.02758 14.0838 2.95607 15.2087 3.73005 15.5813L15.1812 21.0949C15.5069 21.2516 15.8199 21.4296 16.1186 21.627L24.9382 12.8075C25.5607 12.1849 26.57 12.1849 27.1926 12.8075C27.8151 13.43 27.8151 14.4393 27.1926 15.0619L18.373 23.8815C18.5703 24.1801 18.7483 24.4931 18.905 24.8186L24.4186 36.27C24.7913 37.0439 25.9161 36.9724 26.1878 36.1575L36.7555 4.45432Z"
        fill={fill || theme.colors.propBlue}
      />
    </svg>
  )
}
export default FormSubmitSuccessIcon

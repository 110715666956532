"use client"
import styled from "@emotion/styled"
import { Tab, Tabs } from "@mui/material"
import moment from "moment"
import { Dispatch, SetStateAction } from "react"
import { extractProperty, filterClick } from "utils"
import { sharedColors } from "./theme/colors"

const StyledTabs = styled(Tabs)`
  margin-top: 16px;
  .MuiTabs-flexContainer {
    gap: 16px;
  }
  .MuiButtonBase-root {
    min-width: 35px;
  }
  .MuiTab-root {
    color: #6f6f6f;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 0px;
    border-bottom: 2px solid transparent;
    padding: 0px;
  }
  .Mui-selected {
    border-bottom: 2px solid ${(p) => p.theme.colors.propBlue};
    color: ${sharedColors.black};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
`
type Props = {
  tabs: any
  setValue: Dispatch<SetStateAction<number>>
  value: number
  eventName?: string
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

function TabTitle({ tabs, setValue, value, eventName = "" }: Props) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabTitle = moment(extractProperty(tabs[newValue], "tabTitle")).format("YYYY")
    filterClick(tabTitle, eventName)
    setValue(newValue)
  }

  return (
    <StyledTabs value={value} onChange={handleChange} aria-label="person tabs">
      {tabs.map((block: any, index: number) => (
        <Tab
          key={"tab" + index.toString()}
          label={moment(extractProperty(block, "tabTitle")).format("YYYY")}
          {...a11yProps(index)}
        />
      ))}
    </StyledTabs>
  )
}

export default TabTitle

"use client"
import { useTheme } from "@emotion/react"
import { SVGProps } from "react"
const FormSuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1718 0.274823C47.222 -1.07524 51.0752 2.77797 49.7251 6.82815L36.5155 46.4571C35.0441 50.8713 28.9511 51.2587 26.9325 47.0663L20.0405 32.7521C19.4529 31.5317 18.4682 30.5469 17.2477 29.9593L2.9337 23.0674C-1.25868 21.0489 -0.87137 14.9559 3.54289 13.4845L43.1718 0.274823ZM45.9444 5.56789C46.2559 4.63324 45.3667 3.74404 44.4321 4.05559L4.80315 17.2652C3.78448 17.6048 3.69508 19.0109 4.66257 19.4767L18.9766 26.3686C19.3836 26.5645 19.7748 26.787 20.1482 27.0338L31.1727 16.0093C31.9509 15.2311 33.2126 15.2311 33.9907 16.0093C34.7689 16.7875 34.7689 18.0492 33.9907 18.8273L22.9662 29.8519C23.2129 30.2252 23.4353 30.6163 23.6312 31.0232L30.5233 45.3374C30.9891 46.3049 32.3952 46.2155 32.7347 45.1968L45.9444 5.56789Z"
        fill={theme.colors.propBlue}
      />
    </svg>
  )
}
export default FormSuccessIcon

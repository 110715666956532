"use client"

import { useTheme } from "@emotion/react"

const AccordionExpandIcon = () => {
  const theme = useTheme()
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="38"
        height="38"
        rx="7"
        stroke={theme.colors.faqIconColorAlternative}
        stroke-width="2"
      />
      <path
        d="M19.5174 23.7416L13.9909 17.9464C13.7244 17.667 13.7244 17.2139 13.9909 16.9344L14.6355 16.2584C14.9016 15.9794 15.3328 15.9789 15.5995 16.2573L20 20.85L24.4005 16.2573C24.6672 15.9789 25.0984 15.9794 25.3645 16.2584L26.0091 16.9344C26.2756 17.2138 26.2756 17.6669 26.0091 17.9464L20.4826 23.7416C20.216 24.021 19.7839 24.021 19.5174 23.7416Z"
        fill={theme.colors.faqIconColorAlternative}
      />
    </svg>
  )
}

export default AccordionExpandIcon

/* eslint-disable quotes */
"use client"
import styled from "@emotion/styled"
import { Tab, Tabs } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"

import { sharedColors } from "./theme/colors"

import { ApexOptions } from "apexcharts"
import "moment/locale/he"
import { useLocale } from "next-intl"
import dynamic from "next/dynamic"
import { filterClick } from "utils"

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })

const StyledStockWrapper = styled.div`
  width: 100%;
  height: 385x;
  padding: 0px;
  border-radius: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 32px 24px 32px 24px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    height: 628px;
  }
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .bottom-price {
    margin-top: 16px;
  }
  .apexcharts-yaxis-title {
    display: none;
  }
  .tooltip-inner-text {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 16px;
    padding-top: 8px;
    font-weight: 600;
  }
  .tooltip-date {
    background-color: ${sharedColors.lightGrey};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 8px;
    padding-right: 16px;
  }
  .MuiTabs-root {
    margin-bottom: 16px;
  }
  .apexchartsarea-chart {
    border-top: 1px solid rgba(247, 247, 247, 1);
    height: 500px !important;
    svg {
      height: 100%;
      text {
        text-anchor: start;
      }
    }
  }
`

const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    color: #6f6f6f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 0px;
    border-bottom: 2px solid transparent;
    min-width: auto;
    padding-left: 8px;
    padding-right: 8px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
    }
  }
  .Mui-selected {
    border-bottom: 2px solid ${(p) => p.theme.colors.propBlue};
    color: ${sharedColors.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    font-weight: 600;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
    }
  }
  .MuiTabs-flexContainer {
    ${(props) => props.theme.breakpoints.down("sm")} {
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      gap: 64px;
    }
  }
`

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const tabsHe = ["שבוע", "חודש", "3 חודשים", "6 חודשים", "שנה", "3 שנים", "5 שנים"]
const tabsEng = ["week", "month", "3 months", "6 months", "year", "3 years", "5 years"]
const monthsHe = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
]
const monthsEng = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "Jule",
  "August",
  "September",
  "October",
  "November",
  "December",
]
const shortMonthsHe = [
  "ינו",
  "פבר",
  "מרץ",
  "אפר",
  "מאי",
  "יונ",
  "יול",
  "אוג",
  "ספט",
  "אוק",
  "נוב",
  "דצמ",
]
const shortMonthsEng = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jule",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
const daysHe = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"]
const daysEng = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const shortDaysHe = ["א", "ב", "ג", "ד", "ה", "ו", "ש"]
const shortDaysEng = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const ONE_WEEk = 7
const ONE_MONTH = 30
const THREE_MONTH = 90
const SIX_MONTH = 180
const ONE_YEAR = 365
const THREE_YEAR = 1095

type Props = {
  data: any
}

function StockDtoGraph({ data }: Props) {
  const [value, setValue] = useState(0)
  const [newData, setNewData] = useState([])
  const [isSafari, setIsSafari] = useState(false)
  const [sliceIndex, setSliceIndex] = useState(ONE_WEEk)
  const locale = useLocale()
  const tabs = locale === "he" ? tabsHe : tabsEng

  useEffect(() => {
    setNewData(data.slice(-ONE_WEEk))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) return null

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    filterClick(tabs[newValue], "מניית קבוצת אשטרום")
    if (newValue === 0) {
      setNewData(data.slice(-ONE_WEEk))
      setSliceIndex(ONE_WEEk)
    }
    if (newValue === 1) {
      setNewData(data.slice(-ONE_MONTH))
      setSliceIndex(ONE_MONTH)
    }
    if (newValue === 2) {
      setNewData(data.slice(-THREE_MONTH))
      setSliceIndex(THREE_MONTH)
    }
    if (newValue === 3) {
      setNewData(data.slice(-SIX_MONTH))
      setSliceIndex(SIX_MONTH)
    }
    if (newValue === 4) {
      setNewData(data.slice(-ONE_YEAR))
      setSliceIndex(ONE_YEAR)
    }
    if (newValue === 5) {
      setNewData(data.slice(-THREE_YEAR))
      setSliceIndex(THREE_YEAR)
    }
    if (newValue === 6) {
      setSliceIndex(SIX_MONTH)
      setNewData(data)
    }
  }

  const options: ApexOptions = {
    chart: {
      id: "area-chart",
      toolbar: {
        show: false, // Hide the toolbar
      },
      zoom: {
        enabled: false,
      },
      defaultLocale: locale,
      locales: [
        {
          name: "he",
          options: {
            months: monthsHe,
            shortMonths: shortMonthsHe,
            days: daysHe,
            shortDays: shortDaysHe,
          },
        },
        {
          name: "en",
          options: {
            months: monthsEng,
            shortMonths: shortMonthsEng,
            days: daysEng,
            shortDays: shortDaysEng,
          },
        },
      ],
    },
    xaxis: {
      type: "datetime",
      categories: newData.map((item: { tradeDate: any }) => item.tradeDate), // Provide xData as categories for the x-axis
      labels: {
        offsetY: 4, // Adjust the vertical position of x-axis labels
        style: {
          fontSize: "16px", // Set the font size for x-axis labels
          fontWeight: 400,
        },
        datetimeFormatter: isSafari
          ? {
              year: "yyyy",
              month: "MM/yyyy",
              day: "dd/MM/yy",
              hour: "HH:mm",
            }
          : {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      opposite: locale === "he",
      align: locale === "he" ? "right" : "left",
      tickAmount: 3,
      labels: {
        offsetX: -30, // Adjust the horizontal position of y-axis labels
        style: {
          fontSize: "18px", // Set the font size for x-axis labels
          fontWeight: 400,
        },
      },
    },
    stroke: {
      colors: ["#097cb6"], // Stroke color of the area chart
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ["#1c7db9"],
      opacity: 1,
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      custom: function ({ dataPointIndex }: { dataPointIndex: number }) {
        const slicedIndex =
          sliceIndex === SIX_MONTH ? dataPointIndex : data.length - sliceIndex + dataPointIndex

        const { adjustedClosingPrice, basePrice, high, low, openingPrice, tradeDate, volume } =
          data[slicedIndex]

        return (
          '<div class="custom-tooltip">' +
          `<div class="tooltip-date">${moment(tradeDate, undefined, locale, undefined).format(
            "MMMM  YYYY DD",
          )}</div>` +
          '<div class="tooltip-inner-text">' +
          `<div>BaseRate: ${basePrice}</div>` +
          `<div>CloseRate: ${adjustedClosingPrice}</div>` +
          `<div>OpeningRate: ${openingPrice}</div>` +
          `<div>DailyHigh: ${high}</div>` +
          `<div>DailyLow: ${low}</div>` +
          `<div>CloseVolume: ${volume}</div>` +
          `<div class="bottom-price">Size: ${basePrice}</div>` +
          "</div>"
        )
      },
    },
  } as any

  const series = [
    {
      name: "Base Price",
      data: newData.map((item: { basePrice: any }) => item.basePrice),
    },
  ]

  return (
    <StyledStockWrapper>
      <StyledTabs value={value} onChange={handleChange} aria-label="stock tabs">
        {tabs.map((tab: any, index: number) => (
          <Tab key={"tab" + index.toString()} label={tab} {...a11yProps(index)} />
        ))}
      </StyledTabs>
      {typeof window !== "undefined" && (
        <Chart options={options} series={series} type="area" width="100%" height="470" />
      )}
    </StyledStockWrapper>
  )
}

export default StockDtoGraph

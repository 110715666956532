"use client"

const PolygonIconEng = () => {
  return (
    <svg
      width="101"
      height="141"
      viewBox="0 0 101 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_23475_182729)">
        <path
          d="M2.94089 106.172C1.33634 106.733 1.32483 108.998 2.92358 109.575L85.8445 139.504C93.1091 142.126 100.774 136.744 100.774 129.021L100.774 74.5259C100.774 73.2825 99.5465 72.4111 98.3726 72.8214L2.94089 106.172Z"
          fill="url(#paint0_linear_23475_182729)"
          fill-opacity="0.24"
        />
        <path
          d="M50.2309 3.80465C51.4894 1.46169 53.9338 0 56.5934 0H89.6284C95.7839 0 100.774 4.99002 100.774 11.1455V61.1251C100.774 62.646 99.821 64.0039 98.3908 64.5211L2.57991 99.1694C1.0221 99.7327 -0.408669 98.0763 0.375229 96.617L50.2309 3.80465Z"
          fill="url(#paint1_linear_23475_182729)"
          fill-opacity="0.24"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_23475_182729"
          x1="49.458"
          y1="71.9822"
          x2="49.458"
          y2="144.893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23475_182729"
          x1="49.458"
          y1="0"
          x2="49.458"
          y2="100.774"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_23475_182729">
          <rect
            width="100.774"
            height="140.248"
            rx="11.1455"
            transform="matrix(-1 0 0 1 100.774 0)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PolygonIconEng

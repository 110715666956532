"use client"
import styled from "@emotion/styled"
import Link from "next/link"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { activeArmsClick, extractImage, extractProperty } from "utils"
import {
  Container,
  Desktop,
  LeftArrow,
  Mobile,
  NewText,
  RightArrow,
  TransitionUp,
  useInViewConfig,
} from "./"
import ImageWithAlt from "./ImageWithAlt"
import { delayTime } from "./transitions/utils"

const StyledContainer = styled.div`
  padding: 40px 0 40px 0;
  background: ${(props) => props.theme.colors.propBlue};
  ${(props) => props.theme.breakpoints.up("md")} {
    /* padding: 80px 120px 80px 120px; */
    padding: 60px 0px;
  }
`

const StyledAbsoluteArrow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  button {
    width: 9px;
    height: 13px;
    padding: 0px;
  }
  svg {
    transition: transform 0.3s ease;
    transform-origin: center;
  }
  :hover {
    svg {
      transform: scale(1.5); // Increase the size of the svg
    }
  }
`

const StyledDescContainer = styled.div`
  flex: 1;
`

const StyledSlideContainer = styled.div<{ isFirst: boolean }>`
  background-color: white;
  width: 100%;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 16px;
  min-height: 180px;
`

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
`
const StyledDesktopSlideContainer = styled.div`
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;
`

const StyledDescriptionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 0px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`

type Props = {
  data: any
  locale: string
}

export const ActiveArms: React.FC<Props> = ({ data, locale }) => {
  const title = extractProperty(data, "title")
  const text = extractProperty(data, "text")
  const arms = extractProperty(data, "arms")
  const armsList = arms.map((prop: any) => {
    return {
      logoImage: extractImage(prop, "logoImage"),
      armsText: extractProperty(prop, "armsText"),
      armLink: extractProperty(prop, "armLink"),
    }
  })

  const { ref, inView } = useInView(useInViewConfig)

  return (
    <>
      <StyledContainer ref={ref}>
        <Container>
          <NewText
            desktopFontSize={40}
            desktopFontWeight="400"
            desktopLineHeight="110%"
            mobileFontSize={32}
            mobileFontWeight="400"
            mobileLineHeight={"112.5%"}
            component="h2"
            color={"white"}
          >
            {title}
          </NewText>

          <StyledDescriptionContainer>
            <NewText
              desktopFontSize={18}
              desktopFontWeight="400"
              desktopLineHeight="26px"
              mobileFontSize={16}
              mobileFontWeight="400"
              mobileLineHeight={"23px"}
              component="div"
              color={"white"}
              dangerouslySetInnerHTML={{ __html: text }}
              maxWidth={"800px"}
            />
          </StyledDescriptionContainer>

          <Desktop>
            <StyledGridContainer>
              {armsList.map((item: any, index: number) => (
                <StyledLink
                  href={item?.armLink?.[0]?.url || "#"}
                  key={index.toString()}
                  target={item?.armLink?.[0]?.target}
                  onClick={() => activeArmsClick(item?.armLink?.[0]?.name, title)}
                >
                  <TransitionUp
                    $inView={inView}
                    $delay={index * delayTime * 0.5}
                    style={{ display: "flex", width: "100%" }}
                  >
                    <StyledDesktopSlideContainer>
                      <ImageWithAlt
                        image={item.logoImage}
                        width={170}
                        height={64}
                        style={{
                          objectFit: "contain",
                          marginInlineEnd: "auto",
                          width: "auto",
                          height: 64,
                        }}
                      />
                      <StyledDescContainer>
                        <NewText
                          desktopFontSize={16}
                          desktopFontWeight={400}
                          desktopLineHeight={"23px"}
                          mobileFontSize={14}
                          mobileFontWeight="400"
                          mobileLineHeight={"20.16px"}
                          component="p"
                          color={"black"}
                          style={{ marginBlockStart: 8 }}
                        >
                          {item.armsText}
                        </NewText>
                      </StyledDescContainer>
                      <StyledAbsoluteArrow>
                        {locale === "en" ? <RightArrow /> : <LeftArrow />}
                      </StyledAbsoluteArrow>
                    </StyledDesktopSlideContainer>
                  </TransitionUp>
                </StyledLink>
              ))}
            </StyledGridContainer>
          </Desktop>
        </Container>
        <Mobile>
          <Swiper slidesPerView={"auto"} spaceBetween={24} style={{ padding: "32px 16px" }}>
            {armsList.map((item: any, index: number) => (
              <SwiperSlide key={index.toString()} style={{ width: "242px" }}>
                <StyledLink
                  href={item?.armLink?.[0]?.url || "#"}
                  onClick={() => activeArmsClick(item?.armLink?.[0]?.name, title)}
                >
                  <StyledSlideContainer isFirst={index === 0} key={index.toString()}>
                    <ImageWithAlt
                      image={item.logoImage}
                      width={100}
                      height={64}
                      style={{
                        objectFit: "contain",
                        marginInlineEnd: "auto",
                        width: "auto",
                        height: 64,
                      }}
                    />
                    <NewText
                      desktopFontSize={14}
                      mobileFontSize={16}
                      mobileFontWeight="400"
                      mobileLineHeight={"1.2"}
                      component="p"
                      color={"black"}
                      style={{ marginBlockStart: 8, height: 40 }}
                    >
                      {item.armsText}
                    </NewText>
                    <StyledAbsoluteArrow>
                      {locale === "he" ? <LeftArrow /> : <RightArrow />}
                    </StyledAbsoluteArrow>
                  </StyledSlideContainer>
                </StyledLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </Mobile>
      </StyledContainer>
    </>
  )
}
export default ActiveArms
